<template>
  <div v-if="authUserIsCoach">
    <LoadingMessage v-if="isLoading" :message="'Fetching clients...'"></LoadingMessage>

    <form @submit.prevent v-if="!isLoading" class="d-flex align-items-center bg-dark p-2 rounded">
      <div class="form-floating-cta-container">
        <div class="form-floating-cta">
          <fa-icon
            @click="resetFilter"
            v-if="keyword.length"
            :icon="['fas', 'times-circle']"
            role="button"
          ></fa-icon>
        </div>
        <input
          v-model.trim="keyword"
          ref="keyword"
          type="text"
          class="form-control form-control-dark"
          placeholder="Search client..."
        >
      </div>
      <BootstrapDropdownButton
        :btnIcon="'sort'"
        :btnClass="'btn btn-primary ms-2'"
        :btnHideToggle="true"
      >
        <li v-for="(sorter, sortedIndex) in sorters" :key="`key-${sortedIndex}`">
          <a @click="changeSorting(sorter.key)" class="dropdown-item small" role="button">
            {{ sorter.label }}
          </a>
        </li>
      </BootstrapDropdownButton>
      <IconButton
        @click="fetchClients"
        :icon="'sync-alt'"
        class="btn btn-primary ms-2"
      ></IconButton>
    </form>

    <div v-if="!isLoading" class="rounded border bg-white mt-2">
      <div class="table-responsive">
        <table class="table m-0">
          <thead>
            <tr>
              <th scope="col" class="align-baseline">Name</th>
              <th scope="col" class="align-baseline">
                <Tooltip :content="'7 and 30-day adherence'">
                  Adherence
                  <fa-icon :icon="['fas', 'info-circle']" class="small text-muted"></fa-icon>
                </Tooltip>
              </th>
              <th scope="col" class="align-baseline">Program due</th>
              <th scope="col" class="align-baseline"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!filteredItems.length">
              <td colspan="4">No clients found</td>
            </tr>
            <ClientListItem
              v-for="(client, clientIndex) in filteredItems"
              :key="`key-${client.userId}`"
              :client="client"
              :index="clientIndex + 1"
            ></ClientListItem>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { deepCopy } from '@/core/util';

export default {
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    Tooltip: () => import('@/components/Tooltip'),
    IconButton: () => import('@/components/button/IconButton'),
    ClientListItem: () => import('./ClientListItem.vue'),
    BootstrapDropdownButton: () => import('@/components/bootstrap/BootstrapDropdownButton'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsCoach']),
    ...mapState('config', ['configUIClientListSorter']),
    ...mapState('client', ['clients']),
    filteredItems() {
      if (!this.clients) return [];

      const { keyword } = this;
      let items = deepCopy(this.clients);

      if (keyword.length) {
        const q = keyword.toLowerCase();
        items = items.filter((client) => {
          const { firstName, lastName } = client;
          return firstName.toLowerCase().indexOf(q) >= 0 || lastName.toLowerCase().indexOf(q) >= 0;
        });
      }

      this.applySorting(this.configUIClientListSorter, items);

      return items;
    },
  },
  methods: {
    async fetchClients() {
      this.isLoading = true;
      this.keyword = '';
      try {
        await this.$store.dispatch('client/fetchClients');
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    changeSorting(sort) {
      this.$store.dispatch('config/setUIClientListSorter', {
        vm: this,
        value: sort,
      });
    },
    applySorting(sort, items) {
      switch (sort) {
        case 'nameAsc':
          items.sort((a, b) => {
            const nameA = a.firstName.toUpperCase();
            const nameB = b.firstName.toUpperCase();
            let cmp = 0;
            if (nameA > nameB) cmp = 1;
            if (nameA < nameB) cmp = -1;
            return cmp;
          });
          break;
        case 'nameDesc':
          items.sort((a, b) => {
            const nameA = a.firstName.toUpperCase();
            const nameB = b.firstName.toUpperCase();
            let cmp = 0;
            if (nameA > nameB) cmp = 1;
            if (nameA < nameB) cmp = -1;
            return cmp * -1;
          });
          break;
        case 'programDue':
          items.sort((a, b) => {
            const dueA = a.dueDate ? a.dueDate : 0;
            const dueB = b.dueDate ? b.dueDate : 0;
            let cmp = 0;
            if (dueA > dueB) cmp = 1;
            if (dueA < dueB) cmp = -1;
            return cmp;
          });
          break;
        default:
          break;
      }

      return items;
    },
    resetFilter() {
      this.keyword = '';
      this.$refs.keyword.focus();
    },
  },
  data() {
    return {
      isLoading: false,
      keyword: '',
      sorters: [
        {
          key: 'nameAsc',
          label: 'Name: A - Z',
        },
        {
          key: 'nameDesc',
          label: 'Name: Z - A',
        },
        {
          key: 'programDue',
          label: 'Program due',
        },
      ],
    };
  },
  mounted() {
    if (this.clients === null) {
      this.fetchClients();
    }
  },
};
</script>

<style lang="scss" scoped>
th[scope="col"] {
  &:nth-child(1) {
    width: 55%;
  }
  &:nth-child(2) {
    width: 15%;
  }
  &:nth-child(3) {
    width: 15%;
  }
  &:nth-child(4) {
    width: 15%;
  }
}
</style>
